













import { mapActions, mapGetters } from "vuex";
import CourseHeader from "@/components/course/details/CourseHeader.vue";
import InvoiceDetails from "@/components/course/details/company/InvoiceDetails.vue";
import OnlineCourse from "@/components/course/details/company/InvoiceDetails.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CourseCompanyPage",
  components: {
    CourseHeader,
    InvoiceDetails,
    OnlineCourse,
  },
  computed: {
    ...mapGetters("courses", ["getCourse"]),
    ...mapGetters("organization", ["getOrganizationInfo"]),
  },
  methods: {
    ...mapActions("courses", ["fetchCourse"]),
    ...mapActions("organization", ["fetchOrganizationInfo"]),
    ...mapActions("contacts", ["fetchContacts"]),
  },
  async beforeMount() {
    this.$store.state.courses.course.curriculumId !== this.$route.params.curriculumId &&
      (await (this as any).fetchCourse(this.$route.params.id));
    await (this as any).fetchOrganizationInfo();
    await (this as any).fetchContacts();
  },
});
